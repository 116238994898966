import React from "react"
import '../layouts/tachyons/css/tachyons.css';
import '../layouts/custom.css';

class Nav extends React.Component {

    isActive(value){
      return 'flex mt0 mr24-l qn-cl-grey1 qn-fi-grey1 qn-transition-cl '+((value===this.props.active) ?'active':'');
    }

  render() { 
    return (
      <div className="qn-nav mw9 center">
        <div className="qn-nav-wrapper fl flex w-70-l w-100 qn-bg-dark1 pt16-l pt16 pb16-l pb16 ph32-l ph32-m ph24">
          <a href="/" className="active mr16">
            <p className="inter f5-l f5 mb0 mt0 qn-lh-normal qn-ls-tight db fw8 qn-nav-logo mr16">Insights Hunt</p>
          </a>
          
          <a href="/" className={this.isActive("latest")+" mr16 qn-nav-link"}>
            <p className="inter f6-l f6 mb0 mt0 qn-lh-normal qn-ls-tight db fw6">All List</p>
          </a>
          <a href="/random" className={this.isActive("random")+" mr16 qn-nav-link"}>
            <p className="inter f6-l f6 mb0 mt0 qn-lh-normal qn-ls-tight db fw6">Random</p>
          </a>
        </div>
        <div className="db-l dn qn-nav-wrapper flex w-30-l fr qn-bg-dark1 pt16-l pb16-l pl24-l pr32 tr">
          <a className="flex justify-end mt0 mr0-l mr0 qn-cl-grey1 qn-fi-grey1 qn-transition-cl qn-nav-link-chrome tr" href="https://chrome.google.com/webstore/detail/insights-hunt/mcpkoiegngiibjaedneiglaepmfggdhb" target="_blank" rel="noreferrer">
            <p className="inter f6-l f6 mb0 mt0 qn-lh-normal qn-ls-tight db-l dn fw6">Install Chrome Extension</p>
          </a>
        </div>
        <div className="cl"></div>
    </div>
    )
  }
}

export default Nav