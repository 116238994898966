import React from 'react'
import PropTypes from 'prop-types';


class Carbon extends React.Component {

  componentDidMount() {
  
    const script = document.createElement('script');
    script.setAttribute('async', '');
    script.src = '//cdn.carbonads.com/carbon.js?serve=CE7DL2JI&placement=insightshuntcom';
    script.id = '_carbonads_js';
    const ad = document.querySelector('#ad');
    if (ad) {
    ad.appendChild(script);
    }
    
  }

  render () {

    // const { placement } = this.props

    const css = `
    #carbonads {
        font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu,
        Cantarell, "Helvetica Neue", Helvetica, Arial, sans-serif;
      }
      
      #carbonads {
        display: flex;
        max-width: 240px;
        background-color: transparent;
        box-shadow: 0 0px 0px 0px hsla(0, 0%, 0%, 0);
      }
      
      #carbonads a {
        color: inherit;
        text-decoration: none;
      }
      
      #carbonads a:hover {
        color: inherit;
      }
      
      #carbonads span {
        position: relative;
        display: block;
        overflow: hidden;
      }
      
      #carbonads .carbon-wrap {
        display: flex;
      }
      
      .carbon-img {
        display: block;
        margin: 0;
        line-height: 1;
      }
      
      .carbon-img img {
        display: block;
        margin-bottom: 0.5rem;
      }
      
      .carbon-text {
        font-size: 12px;
        padding: 0px 0px 0px 12px;
        line-height: 1.5;
        text-align: left;
      }
      
      .carbon-poweredby {
        display: block;
        padding: 0px 0px 0px 0px;
        background: transparent;
        text-align: left;
        text-transform: uppercase;
        letter-spacing: .5px;
        font-weight: 600;
        font-size: 9px;
        line-height: 1;
      }
    `
    // const { classes } = this.props;
    return <div id="ad">
            <style dangerouslySetInnerHTML={{ __html: css }} />
          </div>;
  }

}

Carbon.propTypes = {
  classes: PropTypes.object.isRequired,
};

Carbon.defaultProps = {
  placement: 'insightshuntcom'
}

export default Carbon